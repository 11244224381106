var firebaseConfig = {
  apiKey: "AIzaSyCL45mwFtsS6K-U3U-RwpStF0-xh9AMGUM",
  authDomain: "tawtripmanager.firebaseapp.com",
  databaseURL: "https://tawtripmanager-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tawtripmanager",
  storageBucket: "tawtripmanager.appspot.com",
  messagingSenderId: "445361061630",
  appId: "1:445361061630:web:b7c84c08577902e9226faf"
  };

  export default firebaseConfig;