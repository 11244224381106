import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';


import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from '@mui/material/CardContent';
import { GoogleMap, LoadScript, InfoBox, Circle } from '@react-google-maps/api';
import { DriveFileRenameOutlineRounded, Label } from '@mui/icons-material';
import Arrow from 'react-arrow'
import firebase from '../Backend/firebase'
import { LinearProgress } from '@mui/material';
const _ = require("underscore");

const cssStyle = {
    labels: {
        marginTop: 5
    }
};

const containerStyle = {
    width: '70vw',
    height: '40vh'
};

const cent = {
    lat: -3.745,
    lng: -38.523
};

export default function FreightVIew(
    props
) {

    const chasis = props.chasis;
    const route = props.route;
    const plannedDate = props.plannedDate;
    const actualDate = props.actualDate;
    const status = props.status;
    const truck = props.truck;
    const stage = props.stage;
    const driver = props.driver;
    const tripCode = props.tripCode;
    const clientPin = props.clientPin;
    const type = props.type;
    const [points, setPoints] = React.useState([]);
    const [trip, setTrip] = React.useState([]);
    const [headerBackgroundColor, setHeaderBackgroundColor] = React.useState(stage == "enroute" ? 'rgba(250,250,210,0.5)' : 'rgba(220,220,210,0.5)');
    const [map, setMap] = React.useState(null);
    const [center, setCenter] = React.useState(cent);
    const [showDetails, setShowDetails] = React.useState(false);
    const [frontsideUrl, setFrontSideUrl] = React.useState(null)
    const [backsideUrl, setBackSideUrl] = React.useState(null)
    const [leftsideUrl, setLeftSideUrl] = React.useState(null)
    const [rightsideUrl, setRightSideUrl] = React.useState(null)
    const [mattressUrl, setMattressUrl] = React.useState(null)
    const [fridgeTvUrl, setFridgeTvUrl] = React.useState(null)
    const [ceilingUrl, setCeilingUrl] = React.useState(null)
    const [floorUrl, setFloorUrl] = React.useState(null)
    const options = { closeBoxURL: '', enableEventPropagation: true };
    const [loading, setLoading] = React.useState(false);
    const [chasisPicUrl, setChasisPicUrl] = React.useState(null);
    const [inYardDate, setInYardDate] = React.useState("");
    const onLoad = infoBox => {
        console.log('infoBox: ', infoBox)
    };


    const MINUTE_MS = 40 * 120000;

    React.useEffect(() => {
        const interval = setInterval(() => {
            getTrip();
        }, MINUTE_MS);
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.

    }, [])

    async function getTrip() {
        let response = await fetch("https://asia-southeast2-tawtripmanager.cloudfunctions.net/getTrackPosts?tripCode=" + tripCode, {
        });
        let details = await response.json();



        try {

            if (details?.trip?.progress) {
                let prg = parseFloat(details.trip?.progress) * 100;
                prg = Math.round(parseInt(prg));

                details.trip.progress = prg;
            }
        } catch (ex) {
            console.log("critical error 1 FW", ex)
        }

        setTrip(details.trip);

        let points = Object.values(details.track.points);


        points = points.reverse();


        if (points && points[0] && points[0].latLong) {
            let latLong = points[0].latLong;
            setCenter({ lat: latLong.latitude, lng: latLong.longitude });
        }

        setPoints(points);
    }


    const handleChange = (panel) => async (event, isExpanded) => {
        if (isExpanded && stage != "In Yard") {
            try {
                setLoading(true)
                setHeaderBackgroundColor('rgba(210,250,210,1)');
                await getTrip();
                setLoading(false)
            } catch (e) {
                setLoading(false)
                console.log("failed loading trip")
            }


        } else if (isExpanded && stage == "In Yard") {

            setLoading(true)
            var datePath = "1/inyard/" + clientPin + "/" + chasis + "/date";
            var mainPath = "https://upcdn.io/12a1you/raw/uploads/";
            firebase.database.ref(datePath)
                .once('value', snapshot => {
                    let inYardDate = snapshot.val();

                    setInYardDate(inYardDate)


                })

            try {
                let url = mainPath +
                    "inyard/" + clientPin + "/" + chasis + "/chasis.jpg"

                setChasisPicUrl(url)
            } catch (e) {
                setLoading(false)
                setChasisPicUrl(null)
                console.error("critical in yard pictures error" + JSON.stringify(e))
            }
            try {
                let url = mainPath +
                    "inyard/" + clientPin + "/" + chasis + "/frontside.jpg"
                setFrontSideUrl(url)
            } catch (e) {
                setLoading(false)
                setFrontSideUrl(null)
                console.error("critical in yard pictures error" + JSON.stringify(e))
            }
            try {

                let url = mainPath +
                    "inyard/" + clientPin + "/" + chasis + "/backside.jpg"
                setBackSideUrl(url)
            } catch (e) {
                setLoading(false)
                setBackSideUrl(null)
                console.error("critical in yard pictures error" + JSON.stringify(e))
            }
            try {
                let url = mainPath +
                    "inyard/" + clientPin + "/" + chasis + "/leftside.jpg"
                setLeftSideUrl(url)
            } catch (e) {
                setLoading(false)
                setLeftSideUrl(null)
                console.error("critical in yard pictures error" + JSON.stringify(e))
            }
            try {
                let url = mainPath +
                    "inyard/" + clientPin + "/" + chasis + "/rightside.jpg"

                setRightSideUrl(url)
            } catch (e) {
                setLoading(false)
                setRightSideUrl(null)
                console.error("critical in yard pictures error" + JSON.stringify(e))
            }

            try {
                let url = mainPath +
                    "inyard/" + clientPin + "/" + chasis + "/fridge-tv.jpg"

                setFridgeTvUrl(url)
            } catch (e) {
                setLoading(false)
                setFridgeTvUrl(null)
                console.error("critical in yard pictures error" + JSON.stringify(e))
            }
            try {
                let url = mainPath +
                    "inyard/" + clientPin + "/" + chasis + "/mattress.jpg"

                setMattressUrl(url)

                url = await firebase.storage.ref().
                    child("inyard/" + clientPin + "/" + chasis + "/floor.jpg").
                    getDownloadURL()
                setFloorUrl(url)
            } catch (e) {
                setLoading(false)
                setMattressUrl(null)
                console.error("critical in yard pictures error" + JSON.stringify(e))
            }
            try {
                let url = mainPath +
                    "inyard/" + clientPin + "/" + chasis + "/ceiling.jpg"

                setCeilingUrl(url)
                setLoading(false)
            } catch (e) {
                setLoading(false)
                setCeilingUrl(null)
                console.error("critical in yard pictures error" + JSON.stringify(e))
            }


        }
        else {
            setHeaderBackgroundColor(stage == "enroute" ? 'rgba(250,250,210,0.5)' : 'rgba(220,220,210,0.5)');
        }
    };

    return (
        <div>
            <Accordion style={{ marginLeft: 3, marginTop: 20 }} onChange={handleChange("panel1")}>
                <AccordionSummary
                    style={{
                        justifyCotent: "space-between",
                        backgroundColor: "#D0D4D7"

                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Stack alignItems="space-arround">
                        <Stack sx={{ marginLeft: 3 }} direction="row" spacing={1}>
                            <span>Item:</span>
                            <Chip label={type} color="primary" />
                            <span>&nbsp;</span>
                            <span>VIN:</span>
                            <Chip label={chasis} color="success" />
                            <span>&nbsp;</span>
                            <span>Stage:</span>
                            <Chip label={stage} />
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Chip label={(route.split("to"))[0]} color="default" />
                            <Arrow
                                direction="right"
                                shaftWidth={10}
                                shaftLength={100}
                                headWidth={30}
                                headLength={15}
                                fill="transparent"
                                stroke="orange"

                                strokeWidth={2}

                            />
                            <Chip label={(route.split("to"))[1]} color="default" />
                        </Stack>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    {loading && <LinearProgress></LinearProgress>}
                    {trip && !_.isEmpty(trip) && (<div style={{ dsiplay: "flex", flexFlow: "row no-wrap", justifyContent: "space-between" }}>
                        <div >

                            <span>
                                <Card sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                    <CardContent flexDirection="row" >

                                        <Typography sx={{ fontSize: 14 }} color="success" gutterBottom>
                                            Truck        <Chip label={truck} color="default" />
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="success" gutterBottom>
                                            Driver:  <Chip label={driver} color="success" />
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="success" gutterBottom>
                                            Trip Complaince And Completion
                                        </Typography>
                                        {trip.progress && trip.progress <= 100 && <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                            <CircularProgress variant="determinate" value={trip?.progress} />
                                            <Box
                                                sx={{
                                                    top: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                    right: 0,
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Typography variant="caption" component="div" color="secondary">
                                                    {trip?.progress} %
                                                </Typography>
                                            </Box>
                                        </Box>}
                                        <Typography sx={{ mb: 1.5 }} color="secondary">
                                            Trip Code {trip?.tripCode}
                                        </Typography>
                                        <Button>

                                            <a color="inherit" underline="always" href={"https://asia-southeast2-tawtripmanager.cloudfunctions.net/createTripReport?pin=" + trip.pin + "&&business=1&&tripName=" + trip.name + "&&chasis=" + chasis + "&&milepostNumber=0"} rel="noreferrer" target="_blank">Download Trip Report At Pickup</a>
                                        </Button>
                                        <Button>

                                            <a color="inherit" underline="always" href={"https://asia-southeast2-tawtripmanager.cloudfunctions.net/createTripReport?pin=" + trip.pin + "&&business=1&&tripName=" + trip.name + "&&chasis=" + chasis + "&&milepostNumber=1"} rel="noreferrer" target="_blank">Download Trip Report Midway</a>
                                        </Button>
                                        <Button>

                                            <a color="inherit" underline="always" href={"https://asia-southeast2-tawtripmanager.cloudfunctions.net/createTripReport?pin=" + trip.pin + "&&business=1&&tripName=" + trip.name + "&&chasis=" + chasis + "&&milepostNumber=2"} rel="noreferrer" target="_blank">Download Trip Report Part Drop off</a>
                                        </Button>

                                    </CardContent>

                                    <CardContent>

                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={center}
                                            zoom={10}
                                        >
                                            <InfoBox
                                                onLoad={onLoad}
                                                options={options}
                                                position={center}
                                            >
                                                <div style={{ backgroundColor: 'yellow', opacity: 0.75, padding: 1 }}>
                                                    <div style={{ fontSize: 16, fontColor: `#08233B` }}>
                                                        {type + " : " + chasis}
                                                    </div>
                                                </div>
                                            </InfoBox>
                                            <Circle
                                                // optional
                                                onLoad={onLoad}
                                                // required
                                                center={center}
                                                // required
                                                options={options}
                                            />
                                            <></>
                                        </GoogleMap>

                                    </CardContent>


                                </Card>


                            </span>
                        </div>
                        <Button variant="outlined" sx={{
                            alignSelf: 'center',
                            marginLeft: '40%',
                            marginTop: '1%',

                            marginBottom: '1%'
                        }} onClick={async () => {
                            setShowDetails(showDetails ? false : true)
                        }} >{!showDetails ? 'Show History' : 'Hide History'}</Button>
                        <div style={{ dsiplay: "flex", flexDirection: "column", width: "100%" }}>
                            <Timeline        >
                                {(points || []).map((point, index) => {

                                    if (!showDetails && index > 0) {
                                        return;
                                    }
                                    return (<TimelineItem key={index}>

                                        <TimelineSeparator>
                                            <TimelineDot color={index == (0) ? "success" : "secondary"} />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            {index == 0 && (<Typography color="green">
                                                Current
                                            </Typography>)}
                                            <Typography>
                                                {point.date}
                                            </Typography>
                                            <Typography>
                                                {point.location}
                                            </Typography>
                                        </TimelineContent>
                                    </TimelineItem>)
                                })}
                            </Timeline>
                        </div>
                        <Button variant="contained" sx={{
                            alignSelf: 'center',
                            marginLeft: '40%',
                            marginTop: '1%',
                            marginBottom: '1%'
                        }} onClick={async () => {
                            getTrip()
                        }} >Refresh</Button>

                    </div>)}
                    {
                        stage == "In Yard" && (
                            <div style={{ display: "flex", flexFlow: "row wrap", justifyContent: "space-between", maxWidth: "100vw" }}>
                                {inYardDate && (<span style={{ flexDirection: "row", flexBasis: "20%", border: "1px #ccc solid", }}>
                                    <p> Date pictures taken</p>
                                    <p>{inYardDate}</p>
                                </span>)}
                                {chasisPicUrl && (<span style={{ flexDirection: "column", flexBasis: "20%", border: "1px #ccc solid", }}>
                                    <p> Chassis Picture in the Yard</p>
                                    <img style={{ height: 320, width: 480 }} src={chasisPicUrl} />
                                </span>)}
                                {frontsideUrl && (<span style={{ flexDirection: "column", flexBasis: "20%", border: "1px #ccc solid", }}>
                                    <p> Front Side Picture in the Yard</p>
                                    <img style={{ height: 320, width: 480 }} src={frontsideUrl} />
                                </span>)}
                                {backsideUrl && (<span style={{ flexDirection: "column", flexBasis: "20%", border: "1px #ccc solid", }}>
                                    <p> Back Side Picture in the Yard</p>
                                    <img style={{ height: 320, width: 480 }} src={backsideUrl} />
                                </span>)}

                                {leftsideUrl && (<span style={{ flexDirection: "column", flexBasis: "20%", border: "1px #ccc solid", }}>
                                    <p> Left Side Picture in the Yard</p>
                                    <img style={{ height: 320, width: 480 }} src={leftsideUrl} />
                                </span>)}

                                {rightsideUrl && (<span style={{ flexDirection: "column", flexBasis: "20%", border: "1px #ccc solid", }}>
                                    <p> Right Side Picture in the Yard</p>
                                    <img style={{ height: 320, width: 480 }} src={rightsideUrl} />
                                </span>)}

                                {ceilingUrl && (<span style={{ flexDirection: "column", flexBasis: "20%", border: "1px #ccc solid", }}>
                                    <p> Ceiling Picture in the Yard</p>
                                    <img style={{ height: 320, width: 480 }} src={ceilingUrl} />
                                </span>)}

                                {mattressUrl && (<span style={{ flexDirection: "column", flexBasis: "20%", border: "1px #ccc solid", }}>
                                    <p> Mattress Picture in the Yard</p>
                                    <img style={{ height: 320, width: 480 }} src={mattressUrl} />
                                </span>)}

                                {fridgeTvUrl && (<span style={{ flexDirection: "column", flexBasis: "20%", border: "1px #ccc solid", }}>
                                    <p> Fridge/TV  Picture in the Yard</p>
                                    <img style={{ height: 320, width: 480 }} src={fridgeTvUrl} />
                                </span>)}
                                {floorUrl && (<span style={{ flexDirection: "column", flexBasis: "20%", border: "1px #ccc solid", }}>
                                    <p> Floor  Picture in the Yard</p>
                                    <img style={{ height: 320, width: 480 }} src={floorUrl} />
                                </span>)}
                            </div>

                        )
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    );
}